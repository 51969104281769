<div class="new-footer">
  <div class="footer-buttons">
    <a class="log-in" routerLink="/{{ lang.current }}/sign-in"
      ><img src="/assets/new/log_in.svg" />{{ 'LOGIN' | translate }}</a
    >
    <app-language-select type="footer"></app-language-select>
    <app-sign-up-button
      location="footer"
      *ngIf="!!isMobileDevice"
      className="in-header"
      >{{ 'GET_SWYPE' | translate }}</app-sign-up-button
    >
  </div>
  <div class="footer-content">
    <div class="company">
      © {{ currentYear }} {{ 'COMPANYNAME' | translate }}
    </div>
    <div class="links">
      <a routerLink="/{{ lang.current }}/terms">{{
        'TERMSLINK' | translate
      }}</a>
      <a routerLink="/{{ lang.current }}/imprint">{{
        'IMPRINTLINK' | translate
      }}</a>
      <a routerLink="/{{ lang.current }}/data-protection">{{
        'DATAPROTECTIONLINK' | translate
      }}</a>
      <a class="optanon-show-settings">{{ 'COOKIE_SETTINGS' | translate }}</a>
    </div>
  </div>
</div>
