import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Document } from 'prismic-javascript/types/documents';
import { map, first } from 'rxjs/operators';
import { PrismicService } from '../prismic.service';
import { ImageWithCtaSlice } from '../slices/image-with-cta-slice';
import { CustomContentSlice } from '../slices/custom-content-slice';
import { VideoSlice } from '../slices/video-slice';
import { AnchorScrollService } from '../anchor-scroll.service';
import { ShowUrlParameterSlice } from '../slices/show-url-parameter-slice';
import { IntroSlice } from '../slices/intro-slice';
import { TariffsSlice } from '../slices/tariffs-slice';
import { FeatureSlice } from '../slices/feature-slice';
import { FourItemsSlice } from '../slices/four-items-slice';
import { FaqsSlice } from '../slices/faqs-slice';
import { GetTheAppSlice } from '../slices/get-the-app-slice';
import { TariffHeaderSlice } from '../slices/tariff-header-slice';
import { ImageWithContentSlice } from '../slices/image-with-content-slice';
import { TutorialStepsSlice } from '../slices/tutorial-steps-slice';
import { TermsSlice } from '../terms-slice';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  slices: any[];
  RichText: any;
  currentPrismicSite: string;

  isVariant: boolean;

  constructor(
    private route: ActivatedRoute,
    private prismic: PrismicService,
    private title: Title,
    private meta: Meta,
    private anchorScrollService: AnchorScrollService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { prismicContent: Document }) => {
      this.currentPrismicSite = data.prismicContent.uid;
      this.isVariant = window.abTest?.[data.prismicContent.uid];
      this.convertPrismicContent(data.prismicContent.data);
    });

    this.anchorScrollService.listen();
  }

  convertPrismicContent(content) {
    content.window___tab_title
      ? this.title.setTitle(content.window___tab_title)
      : this.title.setTitle(
          'swype | your best mobile plan without hidden costs'
        );

    content.window___meta_description
      ? this.meta.updateTag({
          name: 'description',
          content: content.window___meta_description
        })
      : this.meta.updateTag({
          name: 'description',
          content:
            'A revolutionary mobile subscription is on the horizon. Everything new and at your fingertips!'
        });

    const promises = content.body
      .filter(
        slice =>
          !slice.slice_label ||
          (this.isVariant
            ? slice.slice_label === 'variant'
            : slice.slice_label === 'original')
      )
      .map(slice => {
        if (slice.slice_type === 'image_with_cta') {
          return this.convertToImageWithCtaSlice(slice);
        }
        if (slice.slice_type === 'custom_content') {
          return this.convertToCustomContentSlice(slice);
        }
        if (slice.slice_type === 'video') {
          return this.convertToVideoSlice(slice);
        }
        if (slice.slice_type === 'show_url_parameter') {
          return this.convertToShowUrlParamterSlice(slice);
        }
        if (slice.slice_type === 'new_intro') {
          return this.convertToNewIntroSlice(slice);
        }
        if (slice.slice_type === 'tariffs') {
          return this.convertToTariffSlice(slice);
        }
        if (slice.slice_type === 'new_features') {
          return this.convertToNewFeatureSlice(slice);
        }
        if (slice.slice_type === '4items') {
          return this.convertToFourItemsSlice(slice);
        }
        if (slice.slice_type === 'faqs') {
          return this.convertToFaqsSlice(slice);
        }
        if (slice.slice_type === 'get_the_app') {
          return this.convertToGetTheAppSlice(slice);
        }
        if (slice.slice_type === 'tariff_header') {
          return this.convertToTariffHeaderSlice(slice);
        }
        if (slice.slice_type === 'image_with_content') {
          return this.convertToImageWithContentSlice(slice);
        }
        if (slice.slice_type === 'tutorial_steps') {
          return this.convertToTutorialStepsSlice(slice);
        }
        if (slice.slice_type == 'terms') {
          return this.convertToTermsSlice(slice);
        }
      });
    Promise.all(promises).then(res => {
      this.slices = res.filter(s => s);
    });
  }

  convertToShowUrlParamterSlice(slice): ShowUrlParameterSlice {
    return {
      type: 'show-url-parameter',
      id: slice.primary.id,
      parameterName: 'mgm-code',
      text: this.prismic.asHtml(slice.primary.text)
    };
  }

  convertToVideoSlice(slice): VideoSlice {
    return {
      type: 'video',
      video: slice.primary.video,
      width: slice.primary.width,
      height: slice.primary.height,
      showControls: slice.primary.show_controls,
      showOn: slice.primary.show_on
    };
  }

  convertToCustomContentSlice(slice): Promise<CustomContentSlice> {
    return this.route.queryParamMap
      .pipe(
        map((params): CustomContentSlice => {
          let content = this.prismic.asHtml(slice.primary.content);
          slice.items.forEach(replaceData => {
            if (
              replaceData.replacement_value_source === 'url-parameter' &&
              replaceData.replace_with
            ) {
              const match = /{{(.+)}}/.exec(replaceData.replace_with);
              if (match?.length >= 2) {
                const [placeHolder, paramName] = match;
                const paramValue = params.get(paramName);

                let value = '';
                if (placeHolder && paramValue) {
                  value = replaceData.replace_with.replace(
                    placeHolder,
                    paramValue
                  );
                }
                content = content.replace(replaceData.replace_variable, value);
              }
            }
          });
          return {
            type: 'custom_content',
            content: this.sanitizer.bypassSecurityTrustHtml(content),
            id: slice.primary.id,
            spacing: slice.primary.spacing || 'default',
            backgroundColor: slice.primary.background_color
          };
        }),
        first()
      )
      .toPromise<CustomContentSlice>();
  }

  convertToImageWithCtaSlice(slice): ImageWithCtaSlice {
    return {
      type: 'image_with_cta',
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      id: slice.primary.id,
      claim: this.prismic.asHtml(slice.primary.claim),
      mobileCta: this.prismic.asText(slice.primary.mobile_cta),
      desktopCta: this.prismic.asText(slice.primary.desktop_cta),
      link: this.prismic.asUrl(slice.primary.cta_link),
      img: slice.primary.image?.url,
      animateImage: slice.primary.animate_image,
      showAppDownloadBtn: slice.primary.show_app_download_button,
      spacing: slice.primary.spacing || 'default',
      backgroundColor: slice.primary.background_color
    };
  }

  convertToNewIntroSlice(slice): IntroSlice {
    return {
      type: 'new_intro',
      id: slice.primary.id,
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      description: this.prismic.asText(slice.primary.description),
      show_features: slice.primary.show_features,
      features: this.prismic.asList(slice.primary.features),
      mainImage: slice.primary.main_image?.url,
      backgroundImage: slice.primary.background_image?.url
    };
  }

  convertToTariffSlice(slice): TariffsSlice {
    return {
      type: 'tariffs',
      id: slice.primary.id,
      margin_top: slice.primary.margin_top,
      margin_bottom: slice.primary.margin_bottom,
      tariffs_title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.tariffs_title)
      ),
      tariffs_subtitle: this.prismic.asText(slice.primary.tariffs_subtitle),
      show_surf_tariff: slice.primary.show_surf_tariff,
      show_swiss_tariff: slice.primary.show_swiss_tariff,
      show_europe_tariff: slice.primary.show_europe_tariff,
      show_custom_tariff: slice.primary.show_custom_tariff,
      items: slice.items.map(item => {
        return {
          tariff_name: this.prismic.asText(item.tariff_name),
          tariff_price: this.prismic.asText(item.tariff_price),
          internet_package: this.prismic.asText(item.internet_package),
          internet_details: this.prismic.asText(item.internet_details),
          internet_icon: item.internet_icon?.url,
          calling_package: this.prismic.asText(item.calling_package),
          calling_details: this.prismic.asText(item.calling_details),
          calling_icon: item.calling_icon?.url,
          roaming_package: this.prismic.asText(item.roaming_package),
          roaming_details: this.prismic.asText(item.roaming_details),
          roaming_icon: item.roaming_icon?.url,
          button_text: this.prismic.asText(item.button_text)
        };
      })
    };
  }

  convertToNewFeatureSlice(slice): FeatureSlice {
    return {
      type: 'new_features',
      id: slice.primary.id,
      margin_top: slice.primary.margin_top,
      margin_bottom: slice.primary.margin_bottom,
      items: slice.items.map(item => {
        return {
          title: this.sanitizer.bypassSecurityTrustHtml(
            this.prismic.asHtml(item.title)
          ),
          description: this.prismic.asText(item.description),
          button_text: this.prismic.asText(item.button_text),
          button_link: this.prismic.asText(item.button_link),
          external_redirect: item.external_redirect,
          img: item.image?.url,
          icon: item.icon?.url
        };
      })
    };
  }

  convertToFourItemsSlice(slice): FourItemsSlice {
    return {
      type: 'four_items',
      id: slice.primary.id,
      margin_top: slice.primary.margin_top,
      margin_bottom: slice.primary.margin_bottom,
      desktop_columns: slice.primary.desktop_columns,
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      items: slice.items.map(item => {
        return {
          title: this.prismic.asText(item.title),
          subtitle: this.prismic.asText(item.subtitle),
          icon: item.icon?.url
        };
      })
    };
  }

  convertToTutorialStepsSlice(slice): TutorialStepsSlice {
    return {
      type: 'tutorial_steps',
      id: slice.primary.id,
      items: slice.items.map(item => {
        return {
          title: this.prismic.asText(item.title),
          description: this.prismic.asText(item.description),
          show_features: item.show_features,
          features: this.prismic.asList(item.features)
        };
      })
    };
  }

  convertToFaqsSlice(slice): FaqsSlice {
    return {
      type: 'faqs',
      id: slice.primary.id,
      margin_top: slice.primary.margin_top,
      margin_bottom: slice.primary.margin_bottom,
      section_title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.section_title)
      ),
      items: slice.items.map(item => {
        return {
          faq_header: this.prismic.asText(item.faq_header),
          faq_body: this.prismic.asText(item.faq_body)
        };
      })
    };
  }

  convertToGetTheAppSlice(slice): GetTheAppSlice {
    return {
      type: 'get_the_app',
      id: slice.primary.id,
      margin_top: slice.primary.margin_top,
      margin_bottom: slice.primary.margin_bottom,
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      description: this.prismic.asText(slice.primary.description),
      image: slice.primary.image?.url
    };
  }

  convertToTariffHeaderSlice(slice): TariffHeaderSlice {
    return {
      type: 'tariff_header',
      id: slice.primary.id,
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      description: this.prismic.asText(slice.primary.description),
      backgroundImage: slice.primary.background_image?.url,
      button_text: this.prismic.asText(slice.primary.button_text),
      show_features: slice.primary.show_features,
      features: this.prismic.asList(slice.primary.features),
      tariff_to_show: slice.primary.tariff_to_show,
      items: slice.items.map(item => {
        return {
          tariff_name: this.prismic.asText(item.tariff_name),
          tariff_price: this.prismic.asText(item.tariff_price),
          internet_package: this.prismic.asText(item.internet_package),
          internet_details: this.prismic.asText(item.internet_details),
          internet_icon: item.internet_icon?.url,
          calling_package: this.prismic.asText(item.calling_package),
          calling_details: this.prismic.asText(item.calling_details),
          calling_icon: item.calling_icon?.url,
          roaming_package: this.prismic.asText(item.roaming_package),
          roaming_details: this.prismic.asText(item.roaming_details),
          roaming_icon: item.roaming_icon?.url,
          button_text: this.prismic.asText(item.button_text)
        };
      })
    };
  }

  convertToImageWithContentSlice(slice): ImageWithContentSlice {
    return {
      type: 'image_with_content',
      id: slice.primary.id,
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      description: this.prismic.asText(slice.primary.description),
      img: slice.primary.image?.url,
      icon: slice.primary.icon?.url,
      show_button: slice.primary.show_button,
      show_items: slice.primary.show_items,
      items: slice.items.map(item => {
        return {
          text: this.prismic.asText(item.text)
        };
      })
    };
  }

  convertToTermsSlice(slice): TermsSlice {
    return {
      type: 'terms',
      spacing: slice.primary.spacing || 'default',
      backgroundColor: slice.primary.background_color,
      title: this.sanitizer.bypassSecurityTrustHtml(
        this.prismic.asHtml(slice.primary.title)
      ),
      items: slice.items.map(item => {
        return {
          file_title: this.prismic.asText(item.file_title),
          button_text: this.prismic.asText(item.button_text),
          file_name: this.prismic.asText(item.file_name)
        };
      })
    };
  }
}
