import {
  Component,
  AfterViewInit,
  HostListener,
  Inject,
  Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import {
  RouterOutlet,
  Router,
  NavigationEnd,
  Scroll,
  ActivatedRoute
} from '@angular/router';
import { routeAnimations } from './animations';
import { StateService } from './state.service';
import { ApiService } from './api.service';
import { debounce } from './libs/debounce';
import { ResizeService } from './resize.service';
import { TrackingService } from './tracking.service';
import { DOCUMENT } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Meta } from '@angular/platform-browser';

declare global {
  interface Window {
    dataLayer: any;
    abTest: any[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements AfterViewInit {
  showSplash = false;
  hideSplash = false;
  showFooter = false;
  hideRouter = true;
  url: string;

  constructor(
    public translate: TranslateService,
    private lang: LanguageService,
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public state: StateService,
    private api: ApiService,
    private meta: Meta,
    private resizeService: ResizeService,
    private trackingService: TrackingService
  ) {
    let scrollTimeout: any;

    this.router.events
      .pipe(
        filter(
          event => event instanceof Scroll || event instanceof NavigationEnd
        ),
        mergeMap(event => {
          if (event instanceof NavigationEnd) {
            let route = this.activatedRoute;
            while (route.firstChild) {
              route = route.firstChild;
            }
            if (route.outlet === 'primary') {
              return route.data.pipe(map(data => ({ event, data })));
            }
          }
          return of({ event, data: null });
        })
      )
      .subscribe(({ event, data }) => {
        if (event instanceof Scroll) {
          if (event.anchor) {
            if (scrollTimeout) {
              clearTimeout(scrollTimeout);
            }
            this.scrollTo(event.anchor);
          }
          this.url = event.routerEvent.url;
        } else if (event instanceof NavigationEnd) {
          this.updateAlternateLinks();
          this.updateCanonical();

          let source = event.urlAfterRedirects.split('utm_source=')[1];
          if (source) {
            source = source.split('&')[0];
            this.api.type = source;
          }

          this.state.menuActive = false;
          scrollTimeout = setTimeout(
            () => (document.scrollingElement.scrollTop = 0),
            500
          );

          // if test is on a specific URL, the new URL is only ready at NavigationEnd
          // for tests with wider scope, it could be activated earlier, but in case of redirect tests
          // that leads to ugly cancelled network requests
          this.trackingService.triggerOptimizeActivateEvent();
          // delay reporting the virtual page view so if there's an Optimize redirect experiment,
          // only the variant is recorded as page view
          setTimeout(
            () => this.trackingService.triggerVirtualPageView(event),
            500
          );

          if (
            event.url.includes('swype') ||
            event.url.includes('international') ||
            event.url.includes('roaming')
          ) {
            setTimeout(
              () =>
                this.trackingService.viewItem(
                  event.url.substring(4).replace('-', ' ')
                ),
              500
            );
          }
        }
      });
  }

  ngAfterViewInit() {
    setTimeout(() => (this.showSplash = true));
    setTimeout(() => (this.hideRouter = false), 1000);
    setTimeout(() => this.state.doShowRouter(), 2000);
    this.onResize(window);
  }

  @HostListener('window:resize', ['$event.target'])
  @debounce(300)
  onResize(event: Window) {
    this.resizeService.onResize(event.innerWidth, event.innerHeight);
  }

  switchLang(lang: string) {
    this.lang.switchLang(lang);
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData[`animation`]
    );
  }

  scrollTo(anchor: string) {
    const el = document.getElementById(anchor);
    const oldUrl = this.url || '';
    const duration =
      oldUrl.split('#')[0] === this.router.url.split('#')[0] ? 0 : 750;

    if (el) {
      setTimeout(
        () =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          }),
        duration
      );
    }
  }

  updateAlternateLinks() {
    let arr = Array.from(this.document.head.children);
    let linkArray = arr.filter(
      (link: HTMLAnchorElement) => link.rel == 'alternate'
    );

    linkArray.forEach((link: HTMLAnchorElement) => {
      const langFromAlternate = link.href.split('/')[3];

      const newHref = window.location.href.replace(
        /(\/[a-z]{2})(\/|$)/,
        `/${langFromAlternate}$2`
      );

      this.renderer.setAttribute(link, 'href', `${newHref}`);
    });
  }

  updateCanonical() {
    let arr = Array.from(this.document.head.children);
    let canonical = arr.find(
      (link: HTMLAnchorElement) => link.rel == 'canonical'
    );

    this.renderer.setAttribute(canonical, 'href', `${window.location.href}`);
  }
}
