<div class="new-social-login">
  <div class="buttons-container">
    <asl-google-signin-button
      type="standard"
      theme="filled_blue"
      size="medium"
      [locale]="locale"
      text="continue_with"
    ></asl-google-signin-button>
    <button class="fb-button" (click)="loginUsingFacebook($event)">
      <div class="logo-container">
        <img src="/assets/icons/facebook-button.svg" alt="facebook logo" />
      </div>
      {{ 'CONTINUE_WITH' | translate }} Facebook
    </button>
  </div>
  <app-or-divider></app-or-divider>
</div>
<app-spinner-overlay [showspinner]="loading"></app-spinner-overlay>
<notifier-container></notifier-container>
