export interface AddSimOrderBody {
  id?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  nr?: string;
  plz?: string;
  city?: string;
  company?: string;
}
