import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser
} from '@abacritt/angularx-social-login';
import jwtDecode, { JwtPayload } from 'jwt-decode';

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

export type FacebookLoginStatus = {
  status: string;
  authResponse: {
    accessToken: number;
    expiresIn: number;
    signedRequest: string;
    userID: string;
  };
};

export type FacebookLoginResponse = {
  status: string;
  authResponse: {
    accessToken: number;
    expiresIn: number;
    signedRequest: string;
    userID: string;
  };
};

export type FacebookUser = {
  name: string;
  email: string;
};
@Injectable({
  providedIn: 'root'
})
export class AmplifyService {
  constructor(private socialAuthService: SocialAuthService) {
    if (!window.FB) {
      this.createScript();
    }
  }

  createScript = () => {
    // load the facebook sdk
    window.fbAsyncInit = this.fbAsyncInit;
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.onload = this.initFB;
    document.body.appendChild(script);
  };

  googleLoginOptions = {
    scope: 'profile email'
  };

  initFB = () => {
    const fb = window.FB;
  };

  fbAsyncInit = () => {
    // init the fb sdk client
    const fb = window.FB;
    fb.init({
      appId: '727263601035592',
      cookie: true,
      xfbml: true,
      version: 'v2.11'
    });
  };

  public password = '';

  public loginMethod = 'email';

  async signUp(username, password, email, phoneNumber) {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number: phoneNumber
        }
      });
      this.password = password;
      return user;
    } catch (error) {
      console.log('error signing up:', error);
      return error;
    }
  }

  async confirmSignUp(username, code) {
    let result;
    try {
      result = await Auth.confirmSignUp(username, code);
    } catch (error) {
      result = error.code;
      console.log('error confirming sign up', error);
    }

    return result;
  }

  async signIn(username, password) {
    let user;
    try {
      user = await Auth.signIn(username, password);
    } catch (error) {
      console.log('error signing in', error);
      if (error.code === 'UserNotConfirmedException') {
        user = 'UserNotConfirmedException';
      }
    }

    return user;
  }

  async resendConfirmationCode(username) {
    try {
      await Auth.resendSignUp(username);
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  async signOut() {
    try {
      await Auth.signOut();
      await this.socialAuthService.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async signInFacebook() {
    try {
      const loginStatusResp = await this.getFBLoginStatus();
      if (loginStatusResp.status === 'connected') {
        await this.getAWSCredentialsFB(loginStatusResp.authResponse);
      } else {
        const loginResp = await this.loginToFB();
        if (!loginResp || !loginResp.authResponse) {
          return;
        }
        await this.getAWSCredentialsFB(loginResp.authResponse);
      }
      return 'ok';
    } catch (err) {
      console.log('error signInFacebook: ', err);
    }
  }

  async getFBLoginStatus() {
    return new Promise<FacebookLoginStatus>(async resolve => {
      const fb = window.FB;
      fb.getLoginStatus(response => {
        resolve(response);
      });
    });
  }

  async loginToFB(params = { scope: 'public_profile,email' }) {
    return new Promise<FacebookLoginResponse>(async resolve => {
      const fb = window.FB;
      fb.login(response => {
        resolve(response);
      }, params);
    });
  }

  getAWSCredentialsFB = async response => {
    const { accessToken, expiresIn } = response;
    const date = new Date();
    const expires_at = expiresIn * 1000 + date.getTime();
    if (!accessToken) {
      return;
    }

    const fbUser = await this.meFB();
    if (fbUser) {
      const user = {
        name: fbUser.name,
        email: fbUser.email
      };

      await Auth.federatedSignIn(
        'facebook',
        { token: accessToken, expires_at },
        user
      ).then(credentials => {
        console.log('Facebook signin succeeded.');
      });
      this.loginMethod = 'facebook';
    }
  };

  async meFB() {
    return new Promise<FacebookUser>(async resolve => {
      const fb = window.FB;
      fb.api('/me', { fields: 'name,email' }, response => {
        resolve(response);
      });
    });
  }

  async getCurrentUser() {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.error(error);
    }

    if (user && !user.attributes && user.id) {
      user.attributes = {
        email: user.email,
        sub: user.id
      };
      if (!user.username) {
        user.username = user.id;
      }
    }
    if (user) {
      user.loginMethod = this.loginMethod;
    }
    return user;
  }

  async getCredentials() {
    let secretAccessKey;
    let accessKeyId;
    let sessionToken;

    try {
      const data = (await Auth.currentCredentials()) as any;
      secretAccessKey = data.secretAccessKey;
      accessKeyId = data.accessKeyId;
      sessionToken = data.sessionToken;
    } catch (error) {
      console.error(error);
      return;
    }

    return { secretAccessKey, accessKeyId, sessionToken };
  }

  async startRecovery(email) {
    try {
      await Auth.forgotPassword(email);
    } catch (error) {
      console.error(error);
      return false;
    }

    return true;
  }

  async finishRecovery(username, code, newPassword) {
    let result;
    try {
      result = await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (error) {
      console.log(error);
      result = error;
    }

    return result;
  }
}
