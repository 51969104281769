import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LangaugeResolve } from './language.resolve';
import { LoadingComponent } from './loading/loading.component';
import { ToastsComponent } from './toasts/toasts.component';
import { TokenCheckResolve } from './token-check.resolve';
import { SplashComponent } from './splash/splash.component';
import { AnimatedSimComponent } from './animated-sim/animated-sim.component';
import { LottieModule } from 'ngx-lottie';
import { InViewDirective } from './in-view.directive';
import { SocialIconsComponent } from './social-icons/social-icons.component';
import { ScrollIndicatorComponent } from './scroll-indicator/scroll-indicator.component';
import { ImprintPageComponent } from './imprint-page/imprint-page.component';
import { SendDownloadLinkFormComponent } from './send-download-link-form/send-download-link-form.component';
import { ImageWithCTAComponent } from './image-with-cta/image-with-cta.component';
import { LazyTranslateLoader } from './lazy-translate-loader';
import { CountdownComponent } from './countdown/countdown.component';
import { ReferrerTrackingResolve } from './referrer-tracking.resolve';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CustomContentComponent } from './custom-content/custom-content.component';
import { VideoComponent } from './video/video.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { InfluencerTrackingResolve } from './influencer-tracking.resolve';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ShowUrlParameterSliceComponent } from './show-url-parameter-slice/show-url-parameter-slice.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextFieldComponent } from './text-field/text-field.component';
import { OrDividerComponent } from './or-divider/or-divider.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { ApiModule } from './api-client';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserSubscriptionsComponent } from './user-subscriptions/user-subscriptions.component';
import {
  SocialLoginModule,
  GoogleLoginProvider,
  SocialAuthService,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import { ModalComponent } from './modal/modal.component';
import { SuspendedAccountComponent } from './suspended-account/suspended-account.component';
import { HeaderComponent } from './header/header.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ClickOutsideDirective } from './clickOutside.directive';
import { ButtonContainerComponent } from './button-container/button-container.component';
import { IntroComponent } from './intro/intro.component';
import { TariffsComponent } from './tariffs/tariffs.component';
import { TariffItemComponent } from './tariff-item/tariff-item.component';
import { FeaturesComponent } from './features/features.component';
import { FourItemsComponent } from './four-items/four-items.component';
import { FooterComponent } from './footer/footer.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { FaqsComponent } from './faqs/faqs.component';
import { GetTheAppComponent } from './get-the-app/get-the-app.component';
import { TariffHeaderComponent } from './tariff-header/tariff-header.component';
import { ImageWithContentComponent } from './image-with-content/image-with-content.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AuthContainerComponent } from './auth-container/auth-container.component';
import { SocialLoginComponent } from './social-login/social-login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { RenewPasswordComponent } from './renew-password/renew-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { StoreButtonsComponent } from './store-buttons/store-buttons.component';
import { SignUpButtonComponent } from './sign-up-button/sign-up-button.component';
import { PasswordRequirementsComponent } from './password-requirements/password-requirements.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubscriptionItemComponent } from './subscription-item/subscription-item.component';
import { CheckYourEmailComponent } from './check-your-email/check-your-email.component';
import { TermsComponent } from './terms/terms.component';
import { SwipeDirective } from './swipe.directive';

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: (Provider | EnvironmentProviders)[];
  }
}

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import('lottie-web');
}

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 80,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    ToastsComponent,
    SplashComponent,
    AnimatedSimComponent,
    InViewDirective,
    FaqsComponent,
    SocialIconsComponent,
    ScrollIndicatorComponent,
    ImprintPageComponent,
    SendDownloadLinkFormComponent,
    ImageWithCTAComponent,
    CountdownComponent,
    DownloadButtonComponent,
    LandingPageComponent,
    CustomContentComponent,
    VideoComponent,
    NotFoundPageComponent,
    TooltipComponent,
    ShowUrlParameterSliceComponent,
    TextFieldComponent,
    OrDividerComponent,
    SocialLoginComponent,
    SpinnerOverlayComponent,
    HelpButtonComponent,
    UserSubscriptionsComponent,
    ModalComponent,
    SuspendedAccountComponent,
    HeaderComponent,
    DropdownComponent,
    ClickOutsideDirective,
    ButtonContainerComponent,
    IntroComponent,
    TariffsComponent,
    TariffItemComponent,
    FeaturesComponent,
    FourItemsComponent,
    FooterComponent,
    LanguageSelectComponent,
    FaqsComponent,
    GetTheAppComponent,
    TariffHeaderComponent,
    ImageWithContentComponent,
    TutorialComponent,
    SignInComponent,
    AuthContainerComponent,
    SocialLoginComponent,
    SignUpComponent,
    EmailVerificationComponent,
    RenewPasswordComponent,
    ForgotPasswordComponent,
    DownloadAppComponent,
    StoreButtonsComponent,
    SignUpButtonComponent,
    PasswordRequirementsComponent,
    DashboardComponent,
    SubscriptionItemComponent,
    CheckYourEmailComponent,
    TermsComponent,
    SwipeDirective
  ],
  imports: [
    BrowserModule,
    RecaptchaModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader
      }
    }),
    FontAwesomeModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule,
    NgSelectModule,
    FormsModule,
    SocialLoginModule,
    GoogleSigninButtonModule
  ],
  providers: [
    LangaugeResolve,
    TokenCheckResolve,
    ReferrerTrackingResolve,
    InfluencerTrackingResolve,
    SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true, // keeps the user signed in,
        lang: 'en',
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '432460877181-362se831l2jfkon5a6spq67lrihve6rh'
            ) // your client id
          }
        ],
        onError: err => {
          console.error(err);
          console.log(err);
        }
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
