import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { CampaignService } from './campaign.service';
import { StorageEntryConfig } from './storage-entry-config.class';
import { StorageService } from './storage.service';
import { NavigationEnd } from '@angular/router';
import MD5 from 'crypto-js/md5';
import Hex from 'crypto-js/enc-hex';
import { Visitor, User } from './interfaces/dataLayers';
import { AmplifyService } from './amplify/amplify.service';
import { WebAppService } from './api-client';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  public static readonly EVENT_VIRTUAL_PAGE_VIEW = 'virtual_page_view';
  public static readonly EVENT_VISITOR_INTERACTION = 'gtm visitor interaction';
  public static readonly EVENT_OPTIMIZE_ACTIVATE = 'optimize.activate';
  public static readonly EVENT_SIGN_UP_INITIATED = 'sign_up_initiated';
  public static readonly EVENT_SIGN_UP_SUCCESSFUL = 'sign_up';
  public static readonly EVENT_ADD_TO_CART = 'add_to_cart';
  public static readonly EVENT_VIEW_ITEM = 'view_item';
  public static readonly EVENT_GET_SWYPE = 'get_swype';

  public static readonly VI_NAME_BUTTON_INTERACTION = 'button interaction';
  public static readonly VI_NAME_FORM_TRACKING = 'form tracking';
  public static readonly VI_NAME_404 = 'page not found';
  public static readonly VI_NAME_FORM_SUBMIT = 'form submit';
  public static readonly VI_NAME_FORM_ERROR = 'form error';

  public static readonly TARIFF_ITEMS = [
    {
      item_name: 'swype Surf',
      item_id: 'swype_surf',
      item_brand: 'swype',
      item_category: 'Mobile plan',
      item_category2: 'Mobile data',
      price: 14.95,
      quantity: 1
    },
    {
      item_name: 'swype Swiss',
      item_id: 'swype_swiss',
      item_brand: 'swype',
      item_category: 'Mobile plan',
      item_category2: 'Mobile voice',
      price: 19.95,
      quantity: 1
    },
    {
      item_name: 'swype Europe',
      item_id: 'swype_europe',
      item_brand: 'swype',
      item_category: 'Mobile plan',
      item_category2: 'Mobile voice',
      price: 34.95,
      quantity: 1
    },
    {
      item_name: 'Roaming',
      item_id: 'roaming',
      item_brand: 'swype',
      item_category: 'Option',
      item_category2: 'Option',
      price: 3,
      quantity: 1
    },
    {
      item_name: 'International',
      item_id: 'international',
      item_brand: 'swype',
      item_category: 'Option',
      item_category2: 'Option',
      price: 2,
      quantity: 1
    }
  ];

  constructor(
    private storage: StorageService,
    private influencer: CampaignService,
    private amplifyService: AmplifyService,
    private webAppService: WebAppService,
    private meta: Meta
  ) {}

  getTariffItemByName(name) {
    return TrackingService.TARIFF_ITEMS.filter(
      item => item.item_name.toLowerCase() === name.toLowerCase()
    );
  }

  getDownloadLink() {
    const influencerName = this.storage.get(
      StorageEntryConfig.INFLUENCER_CAMPAIGN
    );
    if (influencerName && this.influencer[influencerName]) {
      return this.influencer[influencerName].downloadLink;
    }
    return 'https://app.swype.ch';
  }

  getDownloadLinkForStore(store: 'google' | 'apple') {
    if (store === 'apple') {
      return 'https://apps.apple.com/ch/app/swype-mobile-just-better/id1479174713';
    } else if (store === 'google') {
      return 'https://play.google.com/store/apps/details?id=ch.swype';
    } else {
      return 'https://app.swype.ch';
    }
  }

  async createVisitor(): Promise<Visitor> {
    const user = await this.amplifyService.getCurrentUser();

    if (!user) {
      return {
        status: this.storage.get(StorageEntryConfig.USER_LOGGED_ONCE)
          ? 'logged_out'
          : 'anonymous'
      };
    }

    this.storage.save(StorageEntryConfig.USER_LOGGED_ONCE, 'true');

    const data: any = await (
      await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
    ).toPromise();

    return {
      status: 'logged_in'
    };
  }

  async createUser(email?: string): Promise<User> {
    const user = await this.amplifyService.getCurrentUser();

    if (!user) {
      return {
        user: {
          id: undefined,
          email_address: email ? email : ''
        }
      };
    }

    this.storage.save(StorageEntryConfig.USER_LOGGED_ONCE, 'true');

    const data: any = await (
      await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
    ).toPromise();

    return {
      user: {
        id: MD5(user.attributes.sub).toString(Hex),
        email_address: email ? email : user.attributes.email
      }
    };
  }

  async ctaClick(name: string) {
    const influencer = this.storage.get(StorageEntryConfig.INFLUENCER_CAMPAIGN);
    const trackInfluencer =
      ['order now', 'pre order'].includes(name) && influencer;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_BUTTON_INTERACTION,
      button_action: trackInfluencer ? name + ' ' + influencer : name,
      visitor: await this.createVisitor(),
      user: await this.createUser()
    });
  }

  async formFieldBlur(form: string, field: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_FORM_TRACKING,
      form_name: form,
      form_field: field,
      visitor: await this.createVisitor(),
      user: await this.createUser()
    });
  }

  async pageNotFound(path) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_404,
      event_action: path,
      event_label: document.referrer || '',
      visitor: await this.createVisitor(),
      user: await this.createUser()
    });
  }

  async submitForm(formName: string, form: NgForm, error: string = '') {
    if (!form.form.valid && !error) {
      error = Object.keys(form.form.controls)
        .map(key => {
          const control = form.form.controls[key];
          const errors = control.errors ? Object.keys(control.errors) : [];
          if (errors.length) {
            return key;
          }
        })
        .filter(c => c)
        .join(',');
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_FORM_SUBMIT,
      form_name: formName,
      form_submission: !error ? 'successful' : 'not successful',
      visitor: await this.createVisitor(),
      user: await this.createUser()
    });

    if (error) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: TrackingService.EVENT_VISITOR_INTERACTION,
        interaction_name: TrackingService.VI_NAME_FORM_ERROR,
        form_name: formName,
        form_field: error,
        form_message: '',
        visitor: await this.createVisitor(),
        user: await this.createUser()
      });
    }
  }

  async triggerVirtualPageView(event: NavigationEnd) {
    // get rid of locale
    const trimmedPath = event.urlAfterRedirects.split('?')[0];
    const path = trimmedPath.substring(3) || 'home';
    const lang = trimmedPath.substring(1, 3).toUpperCase();
    const title = path.replace('/', '');

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VIRTUAL_PAGE_VIEW,
      page_title: title,
      page_referrer: window.location.href || document.referrer,
      page_location: window.location.href,
      page_type: title,
      language: lang,
      visitor: await this.createVisitor(),
      user: await this.createUser()
    });
  }

  async triggerOptimizeActivateEvent() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_OPTIMIZE_ACTIVATE,
      visitor: await this.createVisitor(),
      user: await this.createUser()
    });
  }

  async signUpInitiated(method: string, email?: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_SIGN_UP_INITIATED,
      method: method,
      visitor: await this.createVisitor(),
      user: await this.createUser(email)
    });
  }

  async signUpVerified(email?: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_SIGN_UP_SUCCESSFUL,
      method: 'email',
      visitor: await this.createVisitor(),
      user: await this.createUser(email)
    });
  }

  viewItem(tariff) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VIEW_ITEM,
      ecommerce: {
        currency: 'CHF',
        items: this.getTariffItemByName(tariff)
      }
    });
  }

  addToCart(tariff) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_ADD_TO_CART,
      ecommerce: {
        currency: 'CHF',
        items: this.getTariffItemByName(tariff)
      }
    });
  }

  getSwype(placement: string) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_GET_SWYPE,
      button_placement: placement
    });
  }
}
