<div
  class="slice get_the_app"
  [ngClass]="[
    sliceData.margin_bottom ? sliceData.margin_bottom : 'small-margin-bottom',
    sliceData.margin_top ? sliceData.margin_top : 'small-margin-top'
  ]"
  id="{{ sliceData.id }}"
>
  <div class="content center-content">
    <div class="image-container">
      <img class="phone" [src]="sliceData.image" alt="phone image" />
      <img class="icon" src="/assets/new/get_app.svg" alt="" />
    </div>
    <div class="text-container">
      <div class="content-title" [innerHTML]="sliceData.title"></div>
      <p class="content-description">{{ sliceData.description }}</p>
      <app-store-buttons *ngIf="!isMobileDevice"></app-store-buttons>
      <button
        class="sign-in-button store-button"
        *ngIf="!!isMobileDevice"
        (click)="openStore()"
      >
        <!--needs to be replaced by new google store icon
          <img
          *ngIf="isAndroid"
          style="margin: 0px 6px 3px 0px;"
          src="/assets/new/google_white.svg"
          alt="google icon"
        />-->
        <img
          *ngIf="!isAndroid"
          style="margin: 0px 6px 6px 0px"
          src="/assets/new/apple_white.svg"
          alt="apple icon"
        />{{ 'TRYFORFREE' | translate }}
      </button>
    </div>
  </div>
</div>
