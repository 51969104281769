import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PrismicService } from './prismic.service';

@Injectable({
  providedIn: 'root'
})
export class PreviewRedirectGuard  {
  constructor(private router: Router, private prismic: PrismicService) {}
  async canActivate(next: ActivatedRouteSnapshot) {
    const token = next.queryParams[`token`];
    const documentId = next.queryParamMap.get(`documentId`);
    const url = await this.prismic.getPreviewUrl(token, documentId);
    return this.router.parseUrl(url);
  }
}
