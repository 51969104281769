<div class="new-header" [ngClass]="backgroundClass">
  <div class="header-container" [ngClass]="{ fixed: state.menuActive }">
    <div class="left-flex-container">
      <div class="logo-container">
        <a routerLink="/{{ lang.current }}">
          <img
            [src]="
              state.menuActive
                ? '/assets/new/swype_red.png'
                : '/assets/new/swype_white.png'
            "
            class="logo"
            alt="swype"
          />
        </a>
      </div>
    </div>
    <div class="right-flex-container">
      <div class="language-mobile" *ngIf="state.menuActive">
        <ng-container *ngTemplateOutlet="languageSelect"></ng-container>
      </div>
      <div
        *ngIf="isMobileDevice"
        class="menu-toggle-container"
        (click)="toggleMenu()"
      >
        <div class="menu-toggle" [ngClass]="{ open: state.menuActive }">
          <span></span>
          <span></span>
        </div>
      </div>

      <div *ngIf="!isMobileDevice" class="desktop-menu">
        <div (clickOutside)="hideDropdown('tarrifs')">
          <button
            class="menu-item"
            (click)="showDropdown('tarrifs')"
            [class.active]="currentSite?.includes('swype')"
          >
            {{ 'TARRIFS' | translate
            }}<img class="chevron_grey" src="/assets/new/chevron_grey.svg" />
            <img class="chevron_white" src="/assets/new/chevron_white.svg" />
          </button>
          <app-dropdown @popUpInOut *ngIf="dropdownOpen === 'tarrifs'">
            <a
              class="dropdown-item"
              routerLink="/{{ lang.current }}/swype-surf"
            >
              <div>
                <p class="title">swype Surf</p>
                <p class="description">
                  {{ 'UNLIMITED_INTERNET' | translate }}
                </p>
              </div>
              <img src="/assets/new/chevron_black.svg" />
            </a>
            <a
              class="dropdown-item"
              routerLink="/{{ lang.current }}/swype-swiss"
            >
              <div>
                <p class="title">swype Swiss</p>
                <p class="description">
                  {{ 'UNLIMITED_EVERYTHING' | translate }}
                </p>
              </div>
              <img src="/assets/new/chevron_black.svg" />
            </a>
            <a
              class="dropdown-item"
              routerLink="/{{ lang.current }}/swype-europe"
            >
              <div>
                <p class="title">swype Europe</p>
                <p class="description">
                  {{ 'UNLIMITED_25COUNTRIES' | translate }}
                </p>
              </div>
              <img src="/assets/new/chevron_black.svg" />
            </a>
          </app-dropdown>
        </div>

        <a
          [class.active]="currentSite === 'tutorial'"
          class="menu-item"
          routerLink="/{{ lang.current }}/tutorial"
          >{{ 'HOW_IT_WORKS' | translate }}</a
        >
        <a
          [class.active]="currentSite === 'esim'"
          class="menu-item"
          routerLink="/{{ lang.current }}/esim"
          >{{ 'ESIM_READY' | translate }}</a
        >
        <a
          [class.active]="currentSite === '5g'"
          class="menu-item"
          routerLink="/{{ lang.current }}/5g"
          >{{ '5G_NETWORK' | translate }}</a
        >
        <a class="menu-item" href="{{ supportLink }}"
          >{{ 'SUPPORT_LINK' | translate }}
        </a>
        <div (clickOutside)="hideDropdown('more')">
          <button class="menu-item" (click)="showDropdown('more')">
            {{ 'MORE' | translate
            }}<img class="chevron_grey" src="/assets/new/chevron_grey.svg" />
            <img class="chevron_white" src="/assets/new/chevron_white.svg" />
          </button>
          <app-dropdown @popUpInOut *ngIf="dropdownOpen === 'more'">
            <a
              class="dropdown-item"
              routerLink="/{{ lang.current }}/international"
            >
              <div>
                <p class="title">{{ 'INTERNATIONAL_CALLING' | translate }}</p>
                <p class="description">
                  {{ 'INTERNATIONAL_CALLING_DESC' | translate }}
                </p>
              </div>
              <img src="/assets/new/chevron_black.svg" />
            </a>
            <a class="dropdown-item" routerLink="/{{ lang.current }}/roaming">
              <div>
                <p class="title">{{ 'ROAMING_ABROAD' | translate }}</p>
                <p class="description">
                  {{ 'ROAMING_ABROAD_DESC' | translate }}
                </p>
              </div>
              <img src="/assets/new/chevron_black.svg" />
            </a>
            <div class="button-container" *ngIf="loggedIn">
              <a
                class="sign-in-button width-100"
                routerLink="/{{ lang.current }}/dashboard"
              >
                {{ 'DASHBOARD_BTN' | translate }}
              </a>
              <button class="log-in-button" (click)="signOut()">
                {{ 'SIGN_OUT_BTN' | translate }}
              </button>
            </div>
            <div class="button-container" *ngIf="!loggedIn">
              <a class="log-in-button" routerLink="/{{ lang.current }}/sign-in">
                {{ 'LOGIN' | translate }}
              </a>
              <app-sign-up-button className="in-header-dropdown">{{
                'SIGN_UP' | translate
              }}</app-sign-up-button>
            </div>
          </app-dropdown>
        </div>
        <a
          class="sign-in-button ml-2 mr-4"
          *ngIf="loggedIn"
          routerLink="/{{ lang.current }}/dashboard"
        >
          {{ 'DASHBOARD_BTN' | translate }}
        </a>
        <app-sign-up-button
          location="header"
          className="in-header"
          *ngIf="!loggedIn"
          >{{ 'GET_SWYPE' | translate }}</app-sign-up-button
        >
        <ng-container *ngTemplateOutlet="languageSelect"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="state.menuActive" class="mobile-menu">
    <div>
      <p class="menu-subtitle">{{ 'TARRIFS' | translate }}</p>
      <a class="menu-item" routerLink="/{{ lang.current }}/swype-surf"
        ><span>swype Surf</span><img src="/assets/new/chevron_black.svg"
      /></a>
      <a class="menu-item" routerLink="/{{ lang.current }}/swype-swiss"
        ><span>swype Swiss</span><img src="/assets/new/chevron_black.svg"
      /></a>
      <a class="menu-item" routerLink="/{{ lang.current }}/swype-europe"
        ><span>swype Europe</span><img src="/assets/new/chevron_black.svg" />
      </a>
    </div>
    <div>
      <p class="menu-subtitle">{{ 'ABOUT' | translate }}</p>
      <a class="menu-item" routerLink="/{{ lang.current }}/tutorial"
        ><span>{{ 'HOW_IT_WORKS' | translate }}</span
        ><img src="/assets/new/chevron_black.svg"
      /></a>
      <a class="menu-item" routerLink="/{{ lang.current }}/esim"
        ><span>{{ 'ESIM_READY' | translate }}</span
        ><img src="/assets/new/chevron_black.svg"
      /></a>
      <a class="menu-item" routerLink="/{{ lang.current }}/5g"
        ><span>{{ '5G_NETWORK' | translate }}</span
        ><img src="/assets/new/chevron_black.svg"
      /></a>
      <a class="menu-item" routerLink="/{{ lang.current }}/international"
        ><span>{{ 'INTERNATIONAL' | translate }}</span
        ><img src="/assets/new/chevron_black.svg"
      /></a>
      <a class="menu-item" routerLink="/{{ lang.current }}/roaming"
        ><span>{{ 'ROAMING_ABROAD' | translate }}</span
        ><img src="/assets/new/chevron_black.svg"
      /></a>
    </div>

    <app-button-container *ngIf="loggedIn">
      <a class="sign-in-button" routerLink="/{{ lang.current }}/dashboard">
        {{ 'DASHBOARD_BTN' | translate }}
      </a>
      <button class="log-in-button" (click)="signOut()">
        {{ 'SIGN_OUT_BTN' | translate }}
      </button>
    </app-button-container>
    <app-button-container *ngIf="!loggedIn">
      <app-sign-up-button location="header" className="in-mobile-menu">{{
        'GET_SWYPE' | translate
      }}</app-sign-up-button>
      <a class="log-in-button" routerLink="/{{ lang.current }}/sign-in">
        {{ 'LOGIN' | translate }}
      </a></app-button-container
    >
  </div>
</div>

<ng-template #languageSelect>
  <app-language-select type="header"></app-language-select>
</ng-template>
