<div
  class="slice"
  [ngClass]="[
    sliceData.margin_bottom ? sliceData.margin_bottom : 'small-margin-bottom',
    sliceData.margin_top ? sliceData.margin_top : 'small-margin-top'
  ]"
  [attr.id]="sliceData.id"
>
  <div class="content center-content" *ngIf="tariffsToShow">
    <div
      class="tariffs-title content-title"
      [innerHTML]="sliceData.tariffs_title"
    ></div>
    <div class="tariffs-subtitle content-subtitle">
      {{ sliceData.tariffs_subtitle }}
    </div>
    <div *ngIf="!isMobileDevice" class="tariffs-container">
      <ng-container *ngIf="!tariffsToShow.includes('custom')">
        <app-tariff-item
          *ngFor="let tariff of tariffsToShow"
          [tariffToDisplay]="tariff"
        >
        </app-tariff-item>
      </ng-container>
      <ng-container *ngIf="tariffsToShow.includes('custom')">
        <app-tariff-item
          *ngFor="let item of sliceData.items"
          tariffToDisplay="custom"
          [customTariff]="item"
        >
        </app-tariff-item>
      </ng-container>
    </div>
    <div
      *ngIf="isMobileDevice"
      class="slider"
      appSwipe
      (next)="
        this.currentSlide + 1 === this.tariffsToShow.length
          ? null
          : showSlide(currentSlide + 1)
      "
      (previous)="this.currentSlide === 0 ? null : showSlide(currentSlide - 1)"
    >
      <ul
        class="slider-wrapper"
        [style.transform]="
          'translateX(-' +
          (position + currentSlide * (100 / tariffsToShow.length)) +
          '%)'
        "
      >
        <ng-container *ngIf="!tariffsToShow.includes('custom')">
          <li
            *ngFor="let tariff of tariffsToShow; let i = index"
            class="slide"
            [class.slide-current]="i === currentSlide"
            [class.slide-next]="i === currentSlide + 1"
            [class.slide-previous]="i === currentSlide - 1"
          >
            <app-tariff-item [tariffToDisplay]="tariff"> </app-tariff-item>
          </li>
        </ng-container>
        <ng-container *ngIf="tariffsToShow.includes('custom')">
          <li
            *ngFor="let item of sliceData.items; let i = index"
            class="slide"
            [class.slide-current]="i === currentSlide"
            [class.slide-next]="i === currentSlide + 1"
            [class.slide-previous]="i === currentSlide - 1"
          >
            <app-tariff-item
              tariffToDisplay="custom"
              [customTariff]="item"
            ></app-tariff-item>
          </li>
        </ng-container>
      </ul>
    </div>
    <ng-container *ngIf="tariffsToShow.length > 1">
      <div class="slider-controls" *ngIf="isMobileDevice">
        <button
          *ngFor="let tariff of tariffsToShow; let i = index"
          [class.active]="currentSlide === i"
          (click)="showSlide(i)"
        ></button>
      </div>
    </ng-container>
  </div>
</div>
