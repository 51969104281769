export interface WebGetUsersResponse {
  __ON_BOARDING_STEP?: string;
  killbillId?: string;
  id?: string;
  name?: string;
  email?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  company?: string;
  city?: string;
  country?: string;
  locale?: string;
  simOrderDates?: Array<string>;
}
