import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageService } from './storage.service';
import { StorageEntryConfig } from './storage-entry-config.class';
import { LanguageService } from './language.service';
import { CampaignService } from './campaign.service';

@Injectable()
export class InfluencerTrackingResolve  {
  constructor(
    private storage: StorageService,
    private router: Router,
    private lang: LanguageService,
    private influencer: CampaignService
  ) {}

  resolve(route: ActivatedRouteSnapshot): any {
    const influencerName = route.url[1]?.path;
    let additionalParams = {};
    if (influencerName && this.influencer[influencerName]) {
      this.storage.save(StorageEntryConfig.INFLUENCER_CAMPAIGN, influencerName);
      additionalParams = {
        utm_source: 'referrer',
        utm_medium: influencerName,
        utm_campaign: 'influencer'
      };
    }
    this.router.navigate([this.lang.current], {
      replaceUrl: false,
      queryParams: {
        ...route.queryParams,
        ...additionalParams
      }
    });
  }
}
