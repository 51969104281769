import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Checks if the :lang parameter is one of the existing languages. If yes, route can activate.
 * If not, this means that the :lang parameter represents the actual path, e.g. /hello and it redirects to /[current-lang]/[lang]
 */
export class LanguageGuard  {
  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    return next.params && this.languageService.langs.includes(next.params.lang)
      ? true
      : this.router.createUrlTree(
          [this.languageService.current, next.params.lang],
          {
            queryParams: next.queryParams
          }
        );
  }
}
