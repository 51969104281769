import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from '../amplify/amplify.service';
import { TrackingService } from '../tracking.service';
import { WebAppService } from '../api-client/api/webApp.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import {
  GoogleLoginProvider,
  GoogleSigninButtonDirective,
  SocialAuthService
} from '@abacritt/angularx-social-login';
import { Auth } from 'aws-amplify';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {
  lang: string;
  locale: string;
  loading = false;
  authServiceSubscription: Subscription;
  GoogleLoginProvider = GoogleLoginProvider;

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private route: ActivatedRoute,
    private tracking: TrackingService,
    private webAppService: WebAppService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private socialAuthService: SocialAuthService
  ) {
    this.lang = this.route.snapshot.params[`lang`];
    this.locale = this.getLanguage();
  }

  getLanguage() {
    switch (this.route.snapshot.params[`lang`]) {
      case 'en':
        return 'en_US';
      case 'de':
        return 'de_CH';
      case 'it':
        return 'it_CH';
      case 'fr':
        return 'fr_CH';
      default:
        return 'en_US';
    }
  }

  async getCurrentUser() {
    this.loading = true;
    const user = await this.amplifyService.getCurrentUser();

    if (user) {
      (
        await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
      ).subscribe(
        response => {
          console.log('webUsersCognitoIdGet', response);
          this.loading = false;
          this.router.navigate(['/' + this.lang + '/dashboard']);
        },
        async error => {
          console.log(error);
          if (error.status === 404) {
            await this.createUser(user);
            this.loading = false;
            this.router.navigate(['/' + this.lang + '/dashboard']);
          }
        }
      );
    }
  }

  async createUser(user) {
    return new Promise(async (resolve, reject) => {
      (
        await this.webAppService.webUsersPut({
          id: user.attributes.sub,
          languageKillbill: this.getLanguage(),
          email: user.attributes.email,
          domain: user.loginMethod
        })
      ).subscribe(
        data => {
          resolve(data);
        },
        error => {
          console.log(error);
          this.notifier.notify(
            'error',
            this.translate.instant('OPERATION_FAILED')
          );
          reject(error);
        }
      );
    });
  }

  async loginUsingFacebook(event) {
    this.tracking.signUpInitiated('facebook');
    const result = await this.amplifyService.signInFacebook();
    console.log('loginUsingFacebook result: ' + result);
    if (result) {
      await this.getCurrentUser();
    }
  }

  ngOnInit() {
    this.authServiceSubscription = this.socialAuthService.authState.subscribe(
      socialUserResp => {
        if (socialUserResp) {
          if (socialUserResp.provider === GoogleLoginProvider.PROVIDER_ID) {
            this.tracking.signUpInitiated('google');

            const user = {
              name: socialUserResp.name,
              email: socialUserResp.email
            };

            const decoded = jwtDecode<JwtPayload>(socialUserResp.idToken);
            const expires_at = decoded.exp * 1000;

            try {
              Auth.federatedSignIn(
                'google',
                { token: socialUserResp.idToken, expires_at },
                user
              ).then(credentials => {
                console.log('google signIn succeeded.');
                this.amplifyService.loginMethod = 'google';
                this.getCurrentUser();
              });
            } catch (error) {
              console.log('error signing out: ', error);
              this.socialAuthService.signOut();
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.authServiceSubscription.unsubscribe();
  }
}
