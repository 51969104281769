import { Component, Input, OnInit } from '@angular/core';
import { ImageWithContentSlice } from '../slices/image-with-content-slice';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-image-with-content',
  templateUrl: './image-with-content.component.html',
  styleUrls: ['./image-with-content.component.scss']
})
export class ImageWithContentComponent {
  @Input() sliceData: ImageWithContentSlice;
  location: string;

  constructor(private router: ActivatedRoute) {
    this.location = this.router.snapshot.url[1].path;
  }
}
