import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from 'aws-amplify';
import { awsExports } from './aws-exports';

if (environment.production) {
  enableProdMode();

  if (window) {
    window.console.log =
      window.console.warn =
      window.console.info =
      window.console.error =
      function () {
        // Don't log anything.
      };
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

Amplify.configure(awsExports);
